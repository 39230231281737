import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InstallationType, NullableNumber, NullableString, SpringType, Step, StepSlugsEnum } from "../../../v2/core/common_types";
import { Color, ColorType, DoorConfiguration, FrameInternalColorType, Option } from "../../../v2/models/door_configuration";

export type DoorConfigurationBuilderState = {
    configuration: DoorConfiguration | null,
    steps: Step[],
    options: Option[], 
    colors: Color[]
}

const initialState: DoorConfigurationBuilderState = {
    configuration: null,
    steps: [],
    options: [], 
    colors: []
}

const reducers = {
    clearStorage(state: DoorConfigurationBuilderState) {
        state.configuration = initialState.configuration;
        state.steps = initialState.steps;
    },
    setConfiguration(state: DoorConfigurationBuilderState, action: PayloadAction<{configuration: DoorConfiguration, options: Option[], colors: Color[]}>) {
        state.configuration = action.payload.configuration;
        state.options = action.payload.options;
        state.colors = action.payload.colors;
    },
    setQuantita(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.quantita = action.payload;
    },
    setVanoConAltroAccesso(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.vanoConAltroAccesso = action.payload;
    },
    setPortinaPedonaleAffiancata(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.portinaPedonaleAffiancata = action.payload;
    },
    setProfonditaGarage(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.profonditaGarage = action.payload;
    },
    setAltezzaArchitrave(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.altezzaArchitrave = Math.max(0, action.payload);
    },
    setSpessoreArchitrave(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.spessoreArchitrave = Math.max(0, action.payload);
    },
    setLarghezzaSpallettaSx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaSpallettaSx = action.payload;
    },
    setSpessoreSpallettaSx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.spessoreSpallettaSx = action.payload;
    },
    setLarghezzaSpallettaDx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaSpallettaDx = action.payload;
    },
    setSpessoreSpallettaDx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.spessoreSpallettaDx = action.payload;
    },
    setLarghezzaForo(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaForo = action.payload;
    },
    setAltezzaForo(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.altezzaForo = action.payload;
    },
    setLarghezzaPorta(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaPorta = action.payload;
    },
    setAltezzaPorta(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.altezzaPorta = action.payload;
    },
    setTipoMolle(state: DoorConfigurationBuilderState, action: PayloadAction<SpringType>) {
        if (!state.configuration) return;
        state.configuration.tipoMolle = action.payload;
    },
    setIsMotorizzata(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.isMotorizzata = action.payload;
    },
    setTipoInstallazioneTraversa(state: DoorConfigurationBuilderState, action: PayloadAction<InstallationType>) {
        if (!state.configuration) return;
        state.configuration.tipoInstallazioneTraversa = action.payload;
    },
    setTipoInstallazioneMontanteDx(state: DoorConfigurationBuilderState, action: PayloadAction<InstallationType>) {
        if (!state.configuration) return;
        state.configuration.tipoInstallazioneMontanteDx = action.payload;
    },
    setTipoInstallazioneMontanteSx(state: DoorConfigurationBuilderState, action: PayloadAction<InstallationType>) {
        if (!state.configuration) return;
        state.configuration.tipoInstallazioneMontanteSx = action.payload;
    },
    setPorta(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.porta = action.payload;
        state.configuration.modello = null;
        state.configuration.finitura = null;
        state.configuration.coloreEsterno = null;
        state.configuration.coloreInterno = null;
    },
    setModello(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.modello = action.payload;
        state.configuration.finitura = null;
        state.configuration.coloreEsterno = null;
        state.configuration.coloreInterno = null;
    },
    setFinitura(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.finitura = action.payload;
        state.configuration.coloreEsterno = null;
        state.configuration.coloreInterno = null;
        state.configuration.finituraVetro = null;
    },
    setFinituraVetro(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.finituraVetro = action.payload;
    },
    setTipoColoreEsterno(state: DoorConfigurationBuilderState, action: PayloadAction<ColorType>) {
        if (!state.configuration) return;
        state.configuration.tipoColoreEsterno = action.payload;
        state.configuration.coloreEsterno = null;
        state.configuration.coloreEsternoNsRal = null;
        state.configuration.coloreEsternoACampione = action.payload == "a_campione";
    },
    setColoreEsterno(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.coloreEsterno = action.payload;
    },
    setColoreEsternoNsRal(state: DoorConfigurationBuilderState, action: PayloadAction<NullableString>) {
        if (!state.configuration) return;
        state.configuration.coloreEsternoNsRal = action.payload;
    },
    setTipoColoreInterno(state: DoorConfigurationBuilderState, action: PayloadAction<ColorType>) {
        if (!state.configuration) return;
        state.configuration.tipoColoreInterno = action.payload;
        state.configuration.coloreInterno = null;
        state.configuration.coloreInternoNsRal = null;
        state.configuration.coloreInternoACampione = action.payload == "a_campione";
    },
    setColoreInterno(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.coloreInterno = action.payload;
    },
    setColoreInternoNsRal(state: DoorConfigurationBuilderState, action: PayloadAction<NullableString>) {
        if (!state.configuration) return;
        state.configuration.coloreInternoNsRal = action.payload;
        state.configuration.coloreInterno = null;
        state.configuration.coloreInternoNsRal = null;
        state.configuration.coloreInternoACampione = false;
    },
    setColoreTelaioInternoDiverso(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.coloreTelaioInternoDiverso = action.payload;
    },
    setStepActive(state: DoorConfigurationBuilderState, action: PayloadAction<{stepSlug: StepSlugsEnum, isActive: boolean}>) {
        let steps = state.steps;
        
        steps.forEach(s => {
            if (s.slug == action.payload.stepSlug) {
                s.isActive = action.payload.isActive;
            } else {
                s.isActive = false;
            }
        });

        state.steps = steps;
    },
    setStepComplete(state: DoorConfigurationBuilderState, action: PayloadAction<{stepSlug: StepSlugsEnum, isComplete: boolean}>) {
        let steps = state.steps;
        
        let step = steps.find(s => s.slug == action.payload.stepSlug);
        if (step) {
            step.isComplete = action.payload.isComplete;
            state.steps = steps;
        }
    },
    setStepVisible(state: DoorConfigurationBuilderState, action: PayloadAction<{stepSlug: StepSlugsEnum, isVisible: boolean}>) {
        let steps = state.steps;
        
        let step = steps.find(s => s.slug == action.payload.stepSlug);
        if (step && step.isVisible != action.payload.isVisible) {
            step.isVisible = action.payload.isVisible;
            state.steps = steps;
        }
    },
    resetSteps(state: DoorConfigurationBuilderState) {
        let steps = state.steps;
        steps.forEach(s => s.isComplete = false);
        state.steps = steps;
    },
    setSteps(state: DoorConfigurationBuilderState, action: PayloadAction<Step[]>) {
        state.steps = action.payload;
    }
};

const slice = createSlice({
    name: "door_configuration_builder",
    initialState,
    reducers
});

const { actions, reducer } = slice;
export function useDoorConfigurationBuilderActions()
{
    return actions;
}
export default reducer;