import React, { FC, useEffect, useState } from "react";
import { IRect } from "konva/lib/types";
import { 
    ALTEZZA_FINESTRA, 
    ALTEZZA_GRIGLIA, 
    DEFAULT_LARGHEZZA_GUARNIZIONE,
    PROFONDITA_INVOLUCRO, 
    DISTANZA_MINIMA_FINESTRA_GRIGLIA, 
    LARGHEZZA_FINESTRA, 
    LARGHEZZA_GRIGLIA, 
    SPESSORE_STIPITE, 
    SPESSORE_TELAIO_FINESTRA, 
    SPESSORE_TELAIO_GRIGLIA, 
    ALTEZZA_PANNELLO_PORTA_CLASSIC, 
    SPESSORE_TELAIO_GLASSPANEL
} from "../../../../../../../core/common_constants";
import { Group, Line, Rect } from "react-konva";
import { distance, toDrawUnit } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { DoorType, FinishType, InstallationType, ModelType, SpringType } from "../../../../../../../core/common_types";
import { Tubolare } from "../tubolare";
import { Color, Option } from "../../../../../../../models/door_configuration";
import { Guarnizione } from "../guarnizione";
import { PannelloRect } from "../../../../../../../core/common_draw_types";
import useImage from 'use-image';

export const PortaSezionalePlana: FC<{larghezzaForo: number, altezzaForo: number, larghezzaPorta: number, altezzaPorta: number, finish: Option, colors: Color[], options: Option[]}> = ({larghezzaPorta, altezzaPorta, colors, larghezzaForo, altezzaForo, finish}) => {
    const lpdraw = toDrawUnit(larghezzaPorta);
    const hpdraw = toDrawUnit(altezzaPorta);
    const lfdraw = toDrawUnit(larghezzaForo);
    const hfdraw = toDrawUnit(altezzaForo);
    const larghezzaGuarnizione = toDrawUnit(DEFAULT_LARGHEZZA_GUARNIZIONE);
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    //const [color, setColor] = useState<HTMLImageElement | null>(null);

    const getColor = (): string => {
        if (!doorConfigurationBuilder.configuration) return "";

        let color = colors.find(c => c?.id == doorConfigurationBuilder.configuration?.coloreEsterno);

        if (!color || !color.img) return "";

        return color.img;
    }

    const [color] = useImage(getColor(), "anonymous", "origin");

    const buildPannelli = (): PannelloRect[] => {
        let tuttoInLuce = doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;

        let hDisp: number = 0;
        let wDisp: number = 0;
        let ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]);
        let ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]);
        let ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]);
        if (tuttoInLuce) {
            hDisp = ptBottomLeft.y - ptTopLeft.y;
            wDisp = ptTopRight.x - ptTopLeft.x;
        } else {
            let vecDirTopLeft = Vector.of([0, 0]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO])).normalize();
            let vecDirTopRight = Vector.of([lpdraw, 0]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, -PROFONDITA_INVOLUCRO])).normalize();
            //let vecDirBottomRight = Vector.of([lpdraw, hpdraw]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, hpdraw + PROFONDITA_INVOLUCRO])).normalize();
            let vecDirBottomLeft = Vector.of([0, hpdraw]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), hpdraw + PROFONDITA_INVOLUCRO])).normalize();
            
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([lfdraw-lpdraw-10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([lfdraw-lpdraw-10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-(lfdraw-lpdraw-10), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            }
            
            hDisp = ptBottomLeft.y - ptTopLeft.y;
            wDisp = ptTopRight.x - ptTopLeft.x;
        }

        let hp: number = hDisp / 2;
        let y: number = ptBottomLeft.y;
        let panels: PannelloRect[] = [];

        panels.push({ 
            bbox: {x: ptTopLeft.x, y: y - hp, width: wDisp, height: hp},
            yCenter: y - (hp / 2),
            selectable: true,
            isTagliato: false
        });

        panels.push({ 
            bbox: {x: ptTopLeft.x, y: y - (2*hp), width: wDisp, height: hp},
            yCenter: y - (hp / 2),
            selectable: true,
            isTagliato: false
        });
        
        return panels;
    }

    const buildGuarnizioneSx = (): {x: number, y: number, length: number, rotation: number} => {
        let tuttoInLuce = doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;

        if (tuttoInLuce) {
            return {
                x: (lfdraw/2)-(lpdraw/2), 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                rotation: 0
            };          
        } else {
            let vecDirTopLeft = Vector.of([0, 0]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO])).normalize();            
            let vecDirBottomLeft = Vector.of([0, hpdraw]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), hpdraw + PROFONDITA_INVOLUCRO])).normalize();
            
            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }
            
            return {
                x: ptTopLeft.x, 
                y: ptTopLeft.y, 
                length: ptBottomLeft.y-ptTopLeft.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneDx = (): {x: number, y: number, length: number, rotation: number} => {
        let tuttoInLuce = doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;

        if (tuttoInLuce) {
            return {
                x: (lfdraw/2)+(lpdraw/2) - larghezzaGuarnizione,
                y: hfdraw - hpdraw,
                length: hpdraw,
                rotation: 0
            }
        } else {
            let vecDirTopRight = Vector.of([lpdraw, 0]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, -PROFONDITA_INVOLUCRO])).normalize();
            let vecDirBottomRight = Vector.of([lpdraw, hpdraw]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, hpdraw + PROFONDITA_INVOLUCRO])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));             
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopRight = Vector.of([lfdraw-10-larghezzaGuarnizione, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-10-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptBottomRight.y-ptTopRight.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneTraversa = (): {x: number, y: number, length: number, rotation: number} => {
        let tuttoInLuce = doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;

        if (tuttoInLuce) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw-hpdraw,
                length: lpdraw,
                rotation: 90
            }
        } else {
            let vecDirTopLeft = Vector.of([0, 0]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO])).normalize();
            let vecDirTopRight = Vector.of([lpdraw, 0]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, -PROFONDITA_INVOLUCRO])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                               
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {            
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                } else {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                }                        
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
            } else {
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                    doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptTopRight.x-ptTopLeft.x, 
                rotation: 90
            }; 
        }
    }

    const buildTubolareSx = (): {x: number, y: number, length: number, width: number, rotation: number} => {
        let tuttoInLuce = doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;
        
        if (tuttoInLuce) {
            return {
                x: (lfdraw/2)-(lpdraw/2) - 10, 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                width: 100,
                rotation: 0
            };
        } else {
            let vecDirTopLeft = Vector.of([0, 0]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO])).normalize();            
            let vecDirBottomLeft = Vector.of([0, hpdraw]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), hpdraw + PROFONDITA_INVOLUCRO])).normalize();

            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptBottomLeft.x, ptBottomLeft.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareDx = (): {x: number, y: number, length: number, width: number, rotation: number} => {
        let tuttoInLuce = doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;

        if (tuttoInLuce) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw - hpdraw,
                length: hpdraw,
                width: 100,
                rotation: 0
            }
        } else {
            let vecDirTopRight = Vector.of([lpdraw, 0]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, -PROFONDITA_INVOLUCRO])).normalize();
            let vecDirBottomRight = Vector.of([lpdraw, hpdraw]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, hpdraw + PROFONDITA_INVOLUCRO])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x,
                y: ptTopRight.y,
                length: distance(ptTopRight.x, ptTopRight.y, ptBottomRight.x, ptBottomRight.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareTraversa = (offset: number): {x: number, y: number, length: number, width: number, rotation: number} => {
        let tuttoInLuce = doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
        doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;

        let width: number = 0;
        if (doorConfigurationBuilder.configuration?.tipoMolle == SpringType.torsione) {
            width = 100;
        } else if (doorConfigurationBuilder.configuration?.tipoMolle == SpringType.trazione) {
            if (doorConfigurationBuilder.configuration?.isMotorizzata) {
                width = 120;
            } else {
                width = 60;
            }
        }

        if (tuttoInLuce) {
            return {
                x: lfdraw-lpdraw - (2 * 10),
                y: hfdraw-hpdraw - offset,
                length: lpdraw + (2 * 10),
                width,
                rotation: 270
            }
        } else {
            let vecDirTopLeft = Vector.of([0, 0]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO])).normalize();
            let vecDirTopRight = Vector.of([lpdraw, 0]).subtract(Vector.of([lpdraw + PROFONDITA_INVOLUCRO, -PROFONDITA_INVOLUCRO])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y - offset,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptTopRight.x, ptTopRight.y),
                width,
                rotation: 270
            }; 
        }
    }

    // const findColor = async () => {
    //     if (!doorConfigurationBuilder.configuration) return;

    //     if (!doorConfigurationBuilder.configuration.coloreEsterno) {
    //         setColor(null);
    //     } else {
    //         let color = colors.find(c => c?.id == doorConfigurationBuilder.configuration?.coloreEsterno);

    //         if (!color || !color.img) return null;

    //         let img = new window.Image();
    //         //img.crossOrigin = 'Anonymous';
    //         img.setAttribute('crossOrigin', 'anonymous');
    //         img.onload = () => {
    //             //setColor({ image: img, width: img.naturalWidth, height: img.naturalHeight });
    //             setColor(img);
    //         }
    //         img.src = color.img;
    //     }
    // }

    const isMontanteSxInLuce = (): boolean => {
        return doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce;
    }

    const isMontanteDxInLuce = (): boolean => {
        return doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce;
    }

    const isTraversaInLuce = (): boolean => {
        return doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce;
    }

    const isRalStandard = (): boolean => {
        if (doorConfigurationBuilder.configuration && 
            doorConfigurationBuilder.configuration.coloreEsternoNsRal == null && 
            !doorConfigurationBuilder.configuration.coloreEsternoACampione) return true;

        return false;
    }

    const renderPanels = () => {
        let pannelli = buildPannelli();
        let totPannelli = pannelli.length;
        
        return pannelli.map((p, idx, ar) => (
            <PannelloPortaPlana
                key={`panel_${(idx+1)}`}
                panelData={p}
                color={color}
                isRalStandard={isRalStandard()}
                finish={finish}
                numeroPannello={(idx+1)}
                totPannelli={totPannelli}
            />
        ));
    }

    // useEffect(() => {
    //     findColor();
    // }, [doorConfigurationBuilder.configuration?.coloreEsterno]);

    if (!doorConfigurationBuilder.configuration) return null;
    if (!doorConfigurationBuilder.configuration.porta) return null;
    if (!doorConfigurationBuilder.configuration.modello) return null;

    return (
        <>
            {renderPanels()}
            {/* <PortaSezionaleClassic altezzaForo={hfdraw} larghezzaForo={lfdraw} larghezzaPorta={lpdraw} altezzaPorta={hpdraw} colors={colors} options={options}>

            </PortaSezionaleClassic> */}

            {/* <MontanteSinistro {...buildMontanteSx()} /> */}

            {isMontanteDxInLuce() && <Tubolare color={color} isRalStandard={isRalStandard()} {...buildTubolareSx()} />}
            {isMontanteSxInLuce() && <Tubolare color={color} isRalStandard={isRalStandard()} {...buildTubolareDx()} />}
            {isTraversaInLuce() && <Tubolare color={color} isRalStandard={isRalStandard()} {...buildTubolareTraversa(0)} />}
            {isTraversaInLuce() && doorConfigurationBuilder.configuration.tipoMolle == SpringType.torsione && <Tubolare color={color} isRalStandard={isRalStandard()} {...buildTubolareTraversa(10)} />}
            
            <Guarnizione {...buildGuarnizioneSx()} />
            <Guarnizione {...buildGuarnizioneDx()} />
            <Guarnizione {...buildGuarnizioneTraversa()} />

            {/* <Circle
                x={lfdraw/2}
                y={hfdraw}
                radius={5}
                fill={"red"}
            />

            <Circle
                x={0}
                y={hfdraw}
                radius={5}
                fill={"red"}
            />

            <Circle
                x={lfdraw}
                y={hfdraw}
                radius={5}
                fill={"red"}
            />

            <Circle
                x={(lfdraw/2)-(lpdraw/2)}
                y={hfdraw}
                radius={5}
                fill={"green"}
            />

            <Circle
                x={(lfdraw/2)+(lpdraw/2)}
                y={hfdraw}
                radius={5}
                fill={"green"}
            /> */}
        </>
    );
}

const PannelloPortaPlana: FC<{finish: Option, color: HTMLImageElement|null|undefined, isRalStandard: boolean, panelData: PannelloRect, numeroPannello: number, totPannelli: number}> = (props) => {
    const delta = toDrawUnit(30/2);
   
    const addFinestre = () => {        
        return (
            <Group x={props.panelData.bbox.x} y={props.panelData.yCenter}>
                <Group x={DISTANZA_MINIMA_FINESTRA_GRIGLIA} y={-(ALTEZZA_FINESTRA/2)}>
                    <Rect
                        x={0}
                        y={0} 
                        width={LARGHEZZA_FINESTRA} 
                        height={ALTEZZA_FINESTRA} 
                        stroke={"#000"} 
                        strokeWidth={0.75}
                        fillPatternImage={props.color ? props.color : undefined}
                        fill={!props.isRalStandard ? "#fff" : undefined}
                    />
                    <Rect
                        x={SPESSORE_TELAIO_FINESTRA}
                        y={SPESSORE_TELAIO_FINESTRA} 
                        width={LARGHEZZA_FINESTRA- (2*SPESSORE_TELAIO_FINESTRA)} 
                        height={ALTEZZA_FINESTRA - (2*SPESSORE_TELAIO_FINESTRA)} 
                        stroke={"#000"} 
                        strokeWidth={0.75}
                        fill={"lightblue"}
                    />
                </Group>
                <Group x={props.panelData.bbox.width-LARGHEZZA_FINESTRA-DISTANZA_MINIMA_FINESTRA_GRIGLIA} y={-(ALTEZZA_FINESTRA/2)}>
                    <Rect
                        x={0}
                        y={0} 
                        width={LARGHEZZA_FINESTRA} 
                        height={ALTEZZA_FINESTRA} 
                        stroke={"#000"} 
                        strokeWidth={0.75}
                        fillPatternImage={props.color ? props.color : undefined}
                        fill={!props.isRalStandard ? "#fff" : undefined}
                    />
                    <Rect
                        x={SPESSORE_TELAIO_FINESTRA}
                        y={SPESSORE_TELAIO_FINESTRA} 
                        width={LARGHEZZA_FINESTRA- (2*SPESSORE_TELAIO_FINESTRA)} 
                        height={ALTEZZA_FINESTRA - (2*SPESSORE_TELAIO_FINESTRA)} 
                        stroke={"#000"} 
                        strokeWidth={0.75}
                        fill={"lightblue"}
                    />
                </Group>
            </Group>
        );
    }

    const addGriglie = () => {
        return (
            <Group x={props.panelData.bbox.x} y={props.panelData.yCenter}>
                <Group x={DISTANZA_MINIMA_FINESTRA_GRIGLIA} y={-(ALTEZZA_GRIGLIA/2)}>
                    <Rect
                        x={0}
                        y={0} 
                        width={LARGHEZZA_GRIGLIA} 
                        height={ALTEZZA_GRIGLIA} 
                        stroke={"#000"} 
                        strokeWidth={0.5}
                        fill={"gray"}
                    />
                    <Rect
                        x={SPESSORE_TELAIO_GRIGLIA}
                        y={SPESSORE_TELAIO_GRIGLIA} 
                        width={LARGHEZZA_GRIGLIA- (2*SPESSORE_TELAIO_GRIGLIA)} 
                        height={ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA)} 
                        stroke={"#000"} 
                        strokeWidth={0.5}
                        fill={"darkgray"}
                    />
                </Group>
                <Group x={props.panelData.bbox.width-LARGHEZZA_GRIGLIA-DISTANZA_MINIMA_FINESTRA_GRIGLIA} y={-(ALTEZZA_GRIGLIA/2)}>
                    <Rect
                        x={0}
                        y={0} 
                        width={LARGHEZZA_GRIGLIA} 
                        height={ALTEZZA_GRIGLIA} 
                        stroke={"#000"} 
                        strokeWidth={0.5}
                        fill={"gray"}
                    />
                    <Rect
                        x={SPESSORE_TELAIO_GRIGLIA}
                        y={SPESSORE_TELAIO_GRIGLIA} 
                        width={LARGHEZZA_GRIGLIA- (2*SPESSORE_TELAIO_GRIGLIA)} 
                        height={ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA)} 
                        stroke={"#000"} 
                        strokeWidth={0.5}
                        fill={"darkgray"}
                    />
                </Group>
                <Group x={DISTANZA_MINIMA_FINESTRA_GRIGLIA+SPESSORE_TELAIO_GRIGLIA} y={0}>
                    <Line
                        points={[0,0,LARGHEZZA_GRIGLIA-(2*SPESSORE_TELAIO_GRIGLIA),0]}                    
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                    <Line
                        points={[0,-((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4),LARGHEZZA_GRIGLIA-(2*SPESSORE_TELAIO_GRIGLIA),-((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4)]}
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                    <Line
                        points={[0,((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4),LARGHEZZA_GRIGLIA-(2*SPESSORE_TELAIO_GRIGLIA),((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4)]}
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                </Group>
                <Group x={props.panelData.bbox.width-LARGHEZZA_GRIGLIA-DISTANZA_MINIMA_FINESTRA_GRIGLIA+SPESSORE_TELAIO_GRIGLIA} y={0}>
                    <Line
                        points={[0,0,LARGHEZZA_GRIGLIA-(2*SPESSORE_TELAIO_GRIGLIA),0]}                    
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                    <Line
                        points={[0,-((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4),LARGHEZZA_GRIGLIA-(2*SPESSORE_TELAIO_GRIGLIA),-((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4)]}
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                    <Line
                        points={[0,((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4),LARGHEZZA_GRIGLIA-(2*SPESSORE_TELAIO_GRIGLIA),((ALTEZZA_GRIGLIA - (2*SPESSORE_TELAIO_GRIGLIA))/4)]}
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                </Group>
            </Group>
        );
    }

    const getFillColor = (): string|undefined => {
        return !props.isRalStandard ? "#fff" : undefined;
    }

    const getFillPattern = ():HTMLImageElement|undefined => {
        if (props.finish?.name != FinishType.glasspanel) {
            return props.color ? props.color : undefined;
        }
        return undefined;
    }

    const renderVetrata = () => {
        return (
            <Group x={props.panelData.bbox.x} y={props.panelData.bbox.y}>
                <Rect
                    x={0}
                    y={0} 
                    width={props.panelData.bbox.width} 
                    height={props.panelData.bbox.height} 
                    stroke={"#000"} 
                    strokeWidth={0.75}
                    fillPatternImage={props.color ? props.color : undefined}
                    fill={!props.isRalStandard ? "#fff" : undefined}
                />
                <Rect
                    x={SPESSORE_TELAIO_GLASSPANEL}
                    y={SPESSORE_TELAIO_GLASSPANEL} 
                    width={props.panelData.bbox.width- (2*SPESSORE_TELAIO_GLASSPANEL)} 
                    height={props.panelData.bbox.height - (2*SPESSORE_TELAIO_GLASSPANEL)} 
                    stroke={"#000"} 
                    strokeWidth={0.75}
                    fill={"lightblue"}
                />
            </Group>
        );
    }

    return (
        <Group>
            <Rect            
                x={props.panelData.bbox.x}
                y={props.panelData.bbox.y} 
                width={props.panelData.bbox.width} 
                height={props.panelData.bbox.height} 
                stroke={"#000"} 
                strokeWidth={0.5}                
                fillPatternImage={getFillPattern()}
                fill={getFillColor()}
                onClick={() => {
                    // if (!p.selectable) return;
                    // alert("Selezionato");
                }}
            />

            {props.finish?.name == FinishType.glasspanel && renderVetrata()}

            {/*props.numeroPannello == (props.totPannelli-1) && addFinestre()*/}
            {/*props.numeroPannello == 1 && addGriglie()*/}
        </Group>
    );
}