import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, toDrawUnit } from "../../../../core/common_functions";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { NullableNumber, NullableString } from "../../../../core/common_types";
import Loading from "../../../../../components/Loading/loading";
import { Alert, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DownloadIcon from "@mui/icons-material/Download";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Scene, SceneTypeRef } from "./components/object-draw/scene";
import { GarageExternal } from "./components/object-draw/garage_external";
import { PROFONDITA_INVOLUCRO } from "../../../../core/common_constants";
import { QuoteAccessory, QuoteSummary } from "../../../../models/door_configuration";
import { useDialogs } from "../../../../providers/dialogs_provider";

export const QuotePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatcher = useStoreDispatch();
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const currentUser = getCurrentUser(base);
    const configurationService = useConfigurationsService();
    const { } = useDoorConfigurationBuilderActions();
    const [isBusy, setIsBusy] = useState(false);
    const { resetSteps } = useDoorConfigurationBuilderActions();
    const dialogs = useDialogs();
    const sceneRef = useRef<SceneTypeRef>(null);
    const [quote, setQuote] = useState<{ progress: boolean, summary: QuoteSummary, error: NullableString }>({ progress: true, summary: null, error: null });

    const loadQuote = async () => {
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

            let summary = await configurationService.getQuote(currentUser, Number(id));

            console.log(summary);

            setQuote({ progress: false, summary, error: null });
        } catch (e: any) {
            let message = "";
            if (typeof e == "string") {
                message = e;
            } else {
                if (e.severity == "error") {
                    message = e.message;
                } else if (e.severity == "warning") {
                    message = e.message;
                }
            }
            setQuote({ progress: false, summary: null, error: message });
        }
    }

    const inviaOrdine = async () => {
        // setIsBusy(true);
        // try {
        //     let res = await axios.get(`${process.env.REACT_APP_API_URL}/send-order?quoteId=${state.pdfData.quote.id}`, axiosConfig);

        //     if (res.data.success == 0) throw res.data.message;

        //     alert("L'ordine è stato inviato con successo");

        //     setIsBusy(false);
        // } catch (e) {
        //     alert("Si è verificato un errore durante l'invio dell'ordine. Ti preghiamo di riprovare.");

        //     setIsBusy(false);
        // }
    }

    const resetPreventivo = async () => {
        setIsBusy(true);
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

            await configurationService.resetQuote(currentUser, Number(id));

            dispatcher(resetSteps());

            navigate(`/door-configurations/${id}/dimensioni`);
        } catch (e: any) {
            setIsBusy(false);

            let message = "";
            if (typeof e == "string") {
                message = e;
            } else {
                if (e.severity == "error") {
                    message = e.message;
                } else if (e.severity == "warning") {
                    message = e.message;
                }
            }
            setQuote({ progress: false, summary: null, error: message });
        }
    }

    const getDescription = (a: QuoteAccessory) => {
        let des = a.name;

        if (a.additionalData) {
            let additionalData = JSON.parse(a.additionalData);
            des += " - " + additionalData.notes;
        }

        return des;
    }

    const buildPdf = async (target: string) => {
        setIsBusy(true);
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

            //let img = await  sceneRef.current?.getImage();

            let preview = await sceneRef.current?.getPreviewAsBlob();
            if (preview) {
                let previewFile = new File([preview], "preview.png", {type: "image/png"})
                await configurationService.savePreview(currentUser, doorConfigurationBuilder.configuration.configurationId, previewFile);
            }

            setIsBusy(false);

            window.open(`${process.env.REACT_APP_WEB_URL}/door-configurations/${id}/${target}/pdf`);
        } catch (e: any) {
            setIsBusy(false);

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            loadQuote();
        }
    }, []);

    if (quote.progress) return <Loading />;

    if (quote.error) return (
        <div className="d-flex justify-content-center mt-3">
            {quote.error && (
                <div>
                    <h2 className="text-primary">Errore durante il reset del preventivo...</h2>
                    <Alert severity="warning">
                        {quote.error}
                    </Alert>
                </div>
            )}
        </div>
    );

    return (
        <>
            <div className="row m-0 h-full">
                <div className="col-xl-6 p-0" style={{ height: "100%" }}>
                    {doorConfigurationBuilder.configuration && (
                        <Scene 
                            ref={sceneRef} 
                            drawRealWidth={toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo) + (4 * PROFONDITA_INVOLUCRO)} 
                            drawRealHeight={toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo) + (2 * PROFONDITA_INVOLUCRO)} 
                            drawXOffset={(2*PROFONDITA_INVOLUCRO)}
                            drawYOffset={PROFONDITA_INVOLUCRO + 40}>
                            <GarageExternal colors={doorConfigurationBuilder.colors} options={doorConfigurationBuilder.options} />
                        </Scene>
                    )}
                </div>

                <div className="col-xl-6 p-0 quote-summary" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
                    <div className="px-4 py-2">
                        <h5 className="text-primary">Riepilogo</h5>

                        {doorConfigurationBuilder.configuration && <div className="mt-3">
                            <h6 className="h6 fw-bold" style={{ borderBottom: "solid 1px var(--bs-primary)" }}>PRODOTTO</h6>
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td>{quote.summary?.data.description}</td>
                                        <td className="text-end">&euro; {quote.summary?.data.productPrice}</td>
                                    </tr>
                                    <tr>
                                        <td><span style={{textTransform: "uppercase"}}>Quantità</span></td>
                                        <td className="text-end">{quote.summary?.data.qty ?? 1}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}

                        {doorConfigurationBuilder.configuration && <div className="mt-3">
                            <h6 className="h6 fw-bold" style={{ borderBottom: "solid 1px var(--bs-primary)" }}>DIMENSIONI DEL VANO</h6>
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="border-bottom">Architrave:</td>
                                        <th className="text-end border-bottom">{doorConfigurationBuilder.configuration.altezzaArchitrave} mm</th>
                                    </tr>
                                    <tr>
                                        <td className="border-bottom">Altezza:</td>
                                        <th className="text-end border-bottom">{doorConfigurationBuilder.configuration.altezzaForo} mm</th>
                                    </tr>
                                    <tr>
                                        <td className="border-bottom">Larghezza:</td>
                                        <th className="text-end border-bottom">{doorConfigurationBuilder.configuration.larghezzaForo} mm</th>
                                    </tr>
                                    <tr>
                                        <td className="border-bottom">Spalletta sinistra:</td>
                                        <th className="text-end border-bottom">{doorConfigurationBuilder.configuration.larghezzaSpallettaSx} mm</th>
                                    </tr>
                                    <tr>
                                        <td>Spalletta destra:</td>
                                        <th className="text-end">{doorConfigurationBuilder.configuration.larghezzaSpallettaDx} mm</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}

                        {doorConfigurationBuilder.configuration && <div className="mt-3">
                            <h6 className="h6 fw-bold" style={{ borderBottom: "solid 1px var(--bs-primary)" }}>MISURE PORTA</h6>
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="border-bottom">Altezza:</td>
                                        <th className="text-end border-bottom">{doorConfigurationBuilder.configuration.altezzaPorta} mm</th>
                                    </tr>
                                    <tr>
                                        <td className="">Larghezza:</td>
                                        <th className="text-end">{doorConfigurationBuilder.configuration.larghezzaPorta} mm</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}

                        {doorConfigurationBuilder.configuration && <div className="mt-3">
                            <h6 className="h6 fw-bold" style={{ borderBottom: "solid 1px var(--bs-primary)" }}>ACCESSORI</h6>
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th className="border-bottom" style={{ width: "12%" }}>CODICE</th>
                                        <th className="border-bottom" style={{ width: "60%" }}>DESCRIZIONE</th>
                                        <th className="border-bottom text-end" style={{ width: "8%" }}>Q.TÀ</th>
                                        <th className="border-bottom text-end" style={{ width: "10%" }}>PREZZO U.</th>
                                        <th className="border-bottom text-end" style={{ width: "10%" }}>TOTALE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quote.summary?.accessories.map((a,idx) => (
                                        <tr key={a.id+"_"+idx}>
                                            <td className="border-bottom align-top">{a.sku}</td>
                                            <td className="border-bottom">{getDescription(a)}</td>
                                            <td className="border-bottom text-end">{a.qty ? a.qty.toFixed(2) : 0} {a.um}</td>
                                            <td className="border-bottom text-end">{a.price ? "€ " + Number(a.price).toFixed(2) : 0}</td>
                                            <td className="border-bottom text-end">{a.qty && a.price ? "€ " + (a.qty * a.price).toFixed(2) : 0}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}

                        {doorConfigurationBuilder.configuration && <div className="mt-3">
                            <h6 className="h6 fw-bold" style={{ borderBottom: "solid 1px var(--bs-primary)" }}>TOTALI</h6>
                            <div className="mt-1 rounded-3 p-3 mt-3 bg-light " style={{ position: "relative" }}>                                
                                <div className="d-flex justify-content-between">
                                    <AmountItem label="Totale merce:" value={quote.summary?.data.baseNetPrice} />
                                </div>

                                <div className="d-flex justify-content-between">
                                    <AmountItem label="Totale servizi:" value={quote.summary?.data.totalServices} />
                                </div>

                                {currentUser.userType != "Retail" && (
                                    <>
                                        <div className="my-1 text-center" style={{ lineHeight: "1" }}>
                                            <hr />
                                            {process.env.NODE_ENV != "production" && <small>Rivendita</small>}
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Sconto:" value={-(quote.summary?.data.retailTotalDiscount ?? 0)} />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale merce netto:" value={quote.summary?.data.retailNet} />

                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale imponibile:" value={quote.summary?.data.retailTaxableAmount} />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale iva:" value={quote.summary?.data.retailTotalTaxes} />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale ordine:" value={quote.summary?.data.retailTotal} />
                                        </div>
                                    </>
                                )}

                                {currentUser.userType == "Retail" && (
                                    <>
                                        <div className="my-1 text-center" style={{ lineHeight: "1" }}>
                                            <hr />
                                            {process.env.NODE_ENV != "production" && <small>Cliente finale</small>}
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Sconto:" value={-(quote.summary?.data.endClientTotalDiscount ?? 0)} />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale merce netto:" value={quote.summary?.data.endClientNet} />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale imponibile:" value={quote.summary?.data.endClientTaxableAmount} />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale iva:" value={quote.summary?.data.endClientTotalTaxes} />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <AmountItem label="Totale ordine:" value={quote.summary?.data.endClientTotal} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>}
                    </div>

                    <div className="d-flex flex-row-reverse px-4 py-2">
                        <Button variant="contained" color="primary" size="small" className="ms-2" onClick={() => inviaOrdine()}>
                            <SendIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                            INVIA ORDINE
                        </Button>

                        {currentUser.userType == "Retail" && (
                            <Button variant="contained" color="primary" size="small" className="ms-2" onClick={(e) => {
                                window.open(`${process.env.REACT_APP_WEB_URL}/door-configurations/${id}/customer/pdf`);
                            }}>
                                <DownloadIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                                SCARICA COPIA CLIENTE
                            </Button>
                        )}

                        <Button variant="contained" color="primary" size="small" className="ms-2" onClick={(e) => buildPdf("retail")}>
                            <DownloadIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                            SCARICA PREVENTVO
                        </Button>

                        <Button variant="contained" color="secondary" size="small" onClick={() => resetPreventivo()}>
                            <RestartAltIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                            RESET
                        </Button>
                    </div>
                </div>
            </div>

            {isBusy && <Loading />}
        </>
    );
}

export const AmountItem: FC<{value: NullableNumber, label: string}> = (props) => {
    if (!props) return <span>ERRORE</span>;

    return (
        <>
            <h6 className="h6">{props.label}</h6>
            <h6 className="h6 text-primary">
                {Number(props.value)
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")} &euro;
            </h6>
        </>
    );
}

export const NumberItem: FC<{value: number, decimal: number, label: string}> = (props) => {
    return (
        <>
            <h6 className="h6">{props.label}</h6>
            <h6 className="h6 text-primary">
                {Number(props.value).toFixed(props.decimal || 0)}
            </h6>
        </>
    );
}