import { IRect, Vector2d } from "konva/lib/types";
import { Accessorio } from "../models/door_configuration";

export type LoadingState = "idle" | "loading" | "load_success" | "load_error";

export enum InstallationType {
    in_luce = 7,
    oltre_luce = 8
}
export enum DoorType {
    plana = "PLANA",
    classic = "CLASSIC",
    full_vision = "FULL VISION"
}

export enum ModelType {
    air = "AIR",
    wide = "WIDE"
}

export enum FinishType {
    glasspanel = "GLASSPANEL",
    special = "SPECIAL",
    material_hpl = "MATERIAL HPL",
    wood_hpl = "WOOD HPL",
    pietravera = "PIETRAVERA",
    full_vision = "FULL VISION"
}

export enum SpringType {
    torsione = 1,
    trazione = 2
}
export type NullableString = string | null | undefined;
export type NullableNumber = number | null | undefined;
export type LengthMeasurementUnits = "m" | "cm" | "mm";

export type Dimensione2D = {
    larghezza: number,
    altezza: number
}

export interface ObjectDrawProps {
    org: Vector2d,
    bbox: IRect
}

export interface RequestResult {
    success: boolean,
    message: NullableString    
}

export interface GenericRequestResult<T> extends RequestResult {
    data: T | null | undefined
}

export interface PaginationResult<T> {
    items: T[],
    itemsCount: number
}

export interface CollectionRequestResult<T> extends RequestResult {
    data: PaginationResult<T>;
}

export type EditorDialogConfirmResult = {
    isValid: boolean,
    message?: string
}

export type StepSlugsEnum = "dimensioni" | "aspetto" | "maniglieria" | "finestratura" | "ventilazione" | "portina-pedonale-affiancata" | "portina-pedonale" | "motorizzazione" | "accessori-motorizzazione" | "accessori-vari" | "generazione-preventivo" | "condizioni";

export type Step = {
    slug: StepSlugsEnum,
    title: string,
    image: string | null,
    isComplete: boolean,
    isActive: boolean,
    isVisible: boolean
}

export type AccessorioViewModel = {
    data: Accessorio, 
    qty: number
} | undefined;

/*
steps: [
    {title: "Dimensioni", slug: "dimensioni", image: "https://media.deltaoverlap.com/public/img/Icone/Dimensioni.png", isVisible: true, isCompleted: false},
    {title: "Aspetto", slug: "aspetto", image: "https://media.deltaoverlap.com/public/img/Icone/Aspetto.png", isVisible: true, isCompleted: false},
    {title: "Maniglierie", slug: "maniglierie", image: "https://media.deltaoverlap.com/public/img/Icone/Maniglia.png", isVisible: true, isCompleted: false},
    {title: "Finestrature", slug: "finestrature", image: "https://media.deltaoverlap.com/public/img/Icone/Finestre.png", isVisible: true, isCompleted: false},
    {title: "Ventilazione", slug: "ventilazione", image: "https://media.deltaoverlap.com/public/img/Icone/Ventilazione.png", isVisible: true, isCompleted: false},
    {title: "Portina Pedonale Integrata", slug: "portina-pedonale", image: "https://media.deltaoverlap.com/public/img/Icone/Porta_integrata.png", isVisible: true, isCompleted: false},
    {title: "Portina Pedonale Affiancata", slug: "portina-pedonale-affiancata", image: "https://media.deltaoverlap.com/public/img/Icone/Porta_laterale.png", isVisible: true, isCompleted: false},
    {title: "Accessori motorizzazione", slug: "accessori-motorizzazione", image: "https://media.deltaoverlap.com/public/img/Icone/Accessori.png", isVisible: true, isCompleted: false},
    {title: "Accessori", slug: "accessori", image: "https://media.deltaoverlap.com/public/img/Icone/Accessori.png", isVisible: true, isCompleted: false},
    {title: "Richiesta", slug: "richiesta", image: "https://media.deltaoverlap.com/public/img/Icone/Richiesta.png", isVisible: true, isCompleted: false}
]
*/