import React, { FC, ReactNode, useEffect, useState } from "react";
import { Circle, Group, Layer, Line, Rect, Text } from "react-konva";
import { toDrawUnit, Vector2dToPointsArray } from "../../../../../../core/common_functions";
import { DEFAULT_COLORE_STIPITE, PROFONDITA_INVOLUCRO, DEFAULT_STROKE_WIDTH, SPESSORE_ARCHITRAVE, SPESSORE_STIPITE } from "../../../../../../core/common_constants";
import { useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { Vector2d } from "konva/lib/types";
import { Vector } from "vecti";
import imagePavimento from "../../../../../../assets/images/pavimento.jpg";
import { Color, Option } from "../../../../../../models/door_configuration";
import { PortaSezionaleClassic } from "./door-models/porta_sezionale_classic";
import { OptionsTypeEnum } from "../../../../../../../core/OptionsTypeEnum";
import { DoorType } from "../../../../../../core/common_types";
import { PortaSezionalePlana } from "./door-models/porta_sezionale_plana";
import { PortaSezionaleFullVision } from "./door-models/porta_sezionale_full_vision";

export const GarageExternal: FC<{colors: Color[], options: Option[]}> = ({colors, options}) => {
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    if (!doorConfigurationBuilder.configuration) return null;

    const findPorta = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.porta);
        return option;
    }

    const findModello = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.modello);
        return option;
    }

    const findFinitura = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.finitura);
        return option;
    }

    const renderPorta = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        if (!doorConfigurationBuilder.configuration.modello) return null;
        if (doorConfigurationBuilder.configuration.altezzaPorta <= 0) return null;
        if (doorConfigurationBuilder.configuration.larghezzaPorta <= 0) return null;

        let porta = findPorta();
        let modello = findModello();
        let finitura = findFinitura();

        if (porta?.name == DoorType.classic) return (
            <PortaSezionaleClassic
                altezzaForo={doorConfigurationBuilder.configuration.altezzaForo} 
                larghezzaForo={doorConfigurationBuilder.configuration.larghezzaForo} 
                altezzaPorta={doorConfigurationBuilder.configuration.altezzaPorta} 
                larghezzaPorta={doorConfigurationBuilder.configuration.larghezzaPorta} 
                modello={modello}
                colors={colors}
                options={options}
            />
        );

        if (porta?.name == DoorType.plana) return (
            <PortaSezionalePlana
                altezzaForo={doorConfigurationBuilder.configuration.altezzaForo} 
                larghezzaForo={doorConfigurationBuilder.configuration.larghezzaForo} 
                altezzaPorta={doorConfigurationBuilder.configuration.altezzaPorta} 
                larghezzaPorta={doorConfigurationBuilder.configuration.larghezzaPorta} 
                finish={finitura}
                colors={colors}
                options={options}
            />
        );

        if (porta?.name == DoorType.full_vision) return (
            <PortaSezionaleFullVision
                altezzaForo={doorConfigurationBuilder.configuration.altezzaForo} 
                larghezzaForo={doorConfigurationBuilder.configuration.larghezzaForo} 
                altezzaPorta={doorConfigurationBuilder.configuration.altezzaPorta} 
                larghezzaPorta={doorConfigurationBuilder.configuration.larghezzaPorta} 
                colors={colors}
                options={options}
            />
        );
    }

    return (
        <Layer>
            <Group x={0} y={0}>
                {/* <Soffitto hg={configurationBuilder.altezzaGarage} lg={configurationBuilder.larghezzaGarage} /> */}
                <Parete h={doorConfigurationBuilder.configuration.altezzaForo} l={doorConfigurationBuilder.configuration.larghezzaForo} />
                <Foro h={doorConfigurationBuilder.configuration.altezzaForo} l={doorConfigurationBuilder.configuration.larghezzaForo} />
                <Pavimento h={doorConfigurationBuilder.configuration.altezzaForo} l={doorConfigurationBuilder.configuration.larghezzaForo} />
                {renderPorta()}                
            </Group>
        </Layer>
    );
}

const Pavimento: FC<{l: number, h: number}> = ({l, h}) => {
    const [pavimento, setPavimento] = useState<{ image: HTMLImageElement, width: number, height: number } | null>(null);

    let lgDraw = toDrawUnit(l);
    let hgDraw = toDrawUnit(h);

    const loadBackgroundPavimento = () => {
        let img = new window.Image();
        img.onload = () => {
          setPavimento({ image: img, width: img.naturalWidth, height: img.naturalHeight });
        }        
        img.src = imagePavimento;
      }
    
    const buildPolyPavimento = (): Vector2d[] => {
        let vecDir = Vector.of([0, hgDraw]).subtract(Vector.of([-PROFONDITA_INVOLUCRO, hgDraw + PROFONDITA_INVOLUCRO])).normalize();
        let vecPt2 = Vector.of([0, hgDraw]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = Vector.of([lgDraw, hgDraw]).subtract(Vector.of([lgDraw + (PROFONDITA_INVOLUCRO), hgDraw + PROFONDITA_INVOLUCRO])).normalize();
        let vecPt3 = Vector.of([lgDraw, hgDraw]).add(vecDir.multiply(SPESSORE_STIPITE));

        let pt1: Vector2d = {x: 0, y: hgDraw};
        let pt2: Vector2d = {x: vecPt2.x, y: vecPt2.y};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y};
        let pt4: Vector2d = {x: lgDraw, y: hgDraw};
        let pt5: Vector2d = {x: lgDraw + PROFONDITA_INVOLUCRO, y: hgDraw};
        let pt6: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO*2), y: hgDraw + PROFONDITA_INVOLUCRO};
        let pt7: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: hgDraw + PROFONDITA_INVOLUCRO};
        let pt8: Vector2d = {x: -PROFONDITA_INVOLUCRO, y: hgDraw};

        return [pt1, pt2, pt3, pt4, pt5, pt6, pt7, pt8];
    }

    useEffect(() => {
        loadBackgroundPavimento();
      }, []);

    return (
        <>
            {pavimento && <Line 
                points={Vector2dToPointsArray(buildPolyPavimento())} 
                stroke={"000"}
                strokeWidth={1}
                fill={"#c7bfbc"}
                closed
                // onClick={() => alert("Hai cliccato il pavimento")}
            />}
        </>
    );
}

const Foro: FC<{l: number, h: number}> = ({l, h}) => {
    const lgDraw = toDrawUnit(l);
    const hgDraw = toDrawUnit(h);
    
    const buildPolyStipiteSx = (): Vector2d[] => {
        let vecDir = Vector.of([0, 0]).subtract(Vector.of([-PROFONDITA_INVOLUCRO, -PROFONDITA_INVOLUCRO])).normalize();
        let vecPt2 = Vector.of([0, 0]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = Vector.of([0, hgDraw]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), hgDraw + PROFONDITA_INVOLUCRO])).normalize();
        let vecPt3 = Vector.of([0, hgDraw]).add(vecDir.multiply(SPESSORE_STIPITE));

        let pt1: Vector2d = {x: 0, y: 0};
        let pt2: Vector2d = {x: vecPt2.x, y: vecPt2.y};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y}
        let pt4: Vector2d = {x: 0, y: hgDraw};

        return [pt1, pt2, pt3, pt4];
    }
        
    const buildPolyStipiteDx = (): Vector2d[] => {
        let vecDir = Vector.of([lgDraw, hgDraw]).subtract(Vector.of([lgDraw + (PROFONDITA_INVOLUCRO), hgDraw + PROFONDITA_INVOLUCRO])).normalize();
        let vecPt3 = Vector.of([lgDraw, hgDraw]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = Vector.of([lgDraw, 0]).subtract(Vector.of([lgDraw + (PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO])).normalize();
        let vecPt4 = Vector.of([lgDraw, 0]).add(vecDir.multiply(SPESSORE_STIPITE));

        let pt1: Vector2d = {x: lgDraw, y: 0};
        let pt2: Vector2d = {x: lgDraw, y: hgDraw};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y};
        let pt4: Vector2d = {x: vecPt4.x, y: vecPt4.y}

        return [pt1, pt2, pt3, pt4];
    }
    
    const buildPolyArchitrave = (): Vector2d[] => {
        let vecDir = Vector.of([lgDraw, 0]).subtract(Vector.of([lgDraw + (PROFONDITA_INVOLUCRO ), -PROFONDITA_INVOLUCRO])).normalize();
        let vecPt3 = Vector.of([lgDraw, 0]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = Vector.of([0, 0]).subtract(Vector.of([-(PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO])).normalize();
        let vecPt4 = Vector.of([0, 0]).add(vecDir.multiply(SPESSORE_STIPITE));
        
        let pt1: Vector2d = {x: 0, y: 0};
        let pt2: Vector2d = {x: lgDraw, y: 0};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y};
        let pt4: Vector2d = {x: vecPt4.x, y: vecPt4.y};

        return [pt1, pt2, pt3, pt4];
    }

    return (
        <>
            <Rect
                x={0} 
                y={0} 
                width={toDrawUnit(l)} 
                height={toDrawUnit(h)} 
                stroke={"#000"} 
                strokeWidth={DEFAULT_STROKE_WIDTH}
            />

            <Line points={Vector2dToPointsArray(buildPolyStipiteSx())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={DEFAULT_COLORE_STIPITE}
                closed
            />

            <Line points={Vector2dToPointsArray(buildPolyStipiteDx())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={DEFAULT_COLORE_STIPITE}
                closed
            />

            <Line points={Vector2dToPointsArray(buildPolyArchitrave())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={DEFAULT_COLORE_STIPITE}
                closed
            />
        </>
    );
}

const Parete: FC<{l: number, h: number}> = ({l, h}) => {
    const lgDraw = toDrawUnit(l);
    const hgDraw = toDrawUnit(h);

    const buildPolyParete = (): Vector2d[] => {
        let pt1: Vector2d = {x: 0, y: 0};
        let pt2: Vector2d = {x: 0, y: hgDraw};
        let pt3: Vector2d = {x: -PROFONDITA_INVOLUCRO, y: hgDraw};        
        let pt4: Vector2d = {x: -PROFONDITA_INVOLUCRO, y: -PROFONDITA_INVOLUCRO};
        let pt5: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO), y: -PROFONDITA_INVOLUCRO};
        let pt6: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO), y: hgDraw};
        let pt7: Vector2d = {x: lgDraw, y: hgDraw};
        let pt8: Vector2d = {x: lgDraw, y: 0};

        // let pt2: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: hgDraw + PROFONDITA_INVOLUCRO};
        // let pt3: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: -PROFONDITA_INVOLUCRO};
        // let pt5: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO*2), y: hgDraw + PROFONDITA_INVOLUCRO};
        // let pt6: Vector2d = {x: lgDraw, y: hgDraw};

        return [pt1, pt2, pt3, pt4, pt5, pt6, pt7, pt8];
    }

    return (
        <>
            <Line points={Vector2dToPointsArray(buildPolyParete())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#293446"}
                closed
                // onClick={() => alert("Hai cliccato la parete esterna")}
            />

            {/*buildPolyParete().map((p, index) => (
                <>
                    <Circle
                        x={p.x}
                        y={p.y}
                        radius={4}
                        fill={"#f00"}
                    />
                    <Text
                        x={p.x}
                        y={p.y}
                        text={"P"+(index+1)}
                    />
                </>
            ))*/}
        </>
    );
}